<template>
  <div class="container">
    <!-- <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="安装时间">
          <el-date-picker size="small" v-model="value2" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="info" size="small" icon="el-icon-download">导出Excel</el-button>
        </el-form-item>
      </el-form>
    </div> -->
    <div class="content">
      <el-table :data="deviceList" stripe style="width: 100%" height="99%">
        <el-table-column prop="organizeName" label="安装公司" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column prop="name" label="安装人员" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column prop="address" label="设备分类" show-overflow-tooltip align="center">
          <el-table-column v-for="(item,i) in deviceLabel" :key="i" :prop="item.prop" :label="item.label" show-overflow-tooltip align="center">
          </el-table-column>
        </el-table-column>
        <el-table-column prop="count" label="合计" show-overflow-tooltip align="center">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      deviceList: [],
      deviceLabel: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      searchForm: {}
    }
  },
  created () {
    this.initTable()
  },
  methods: {
    // 初始化建筑物表格
    async initTable () {
      const { data: result } = await this.$axios.get('/equipment/countByInstall')
      if (result.code === 200) {
        result.data.forEach(item => {
          item.equipmentCountList.forEach((item2, i) => {
            item[`count${i}`] = item2.count
            this.deviceLabel[i] = { label: item2.typeName, prop: `count${i}` }
          })
        })
        this.deviceList = result.data
      } else {
        this.$message.error(result.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0 !important;
}
</style>
